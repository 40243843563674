import { Box, Typography } from "@mui/material";
import { grey, lightBlue } from "@mui/material/colors";
import { useEffect } from "react";
import { CommentDocItem } from "../libs/interface/CommentDocItem.interface";
import React from "react";

interface CommentDocProps {
  items: CommentDocItem[];
}

const Comments: React.FC<CommentDocProps> = ({ items }) => {
  useEffect(() => {}, []);

  return (
    <>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            marginBottom: 2,
            cursor: "pointer",
            maxWidth: "100%",
            boxShadow: "5px 5px 5px",
            backgroundColor: grey[200],
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              alignSelf="left"
              gutterBottom
              variant="body2"
              component="div"
              fontWeight="bold"
              sx={{
                marginTop: 2,
                marginLeft: 2,
              }}
            >
              Autor: {item.author}
            </Typography>
            <Typography
              alignSelf="right"
              gutterBottom
              variant="body2"
              component="div"
              fontStyle="italic"
              color={lightBlue}
              sx={{
                marginTop: 2,
                marginLeft: "auto",
                marginRight: 2,
              }}
            >
              {new Date(item.date).toLocaleString()}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{
                marginLeft: 2,
              }}
            >
              Comentario:
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                marginRight: 2,
                marginBottom: 2,
              }}
            >
              "{item.text}"
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default Comments;
