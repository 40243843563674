import { Box } from "@mui/material";
import React from "react";
import LeftArrowButton from "./LeftArrowButton";
import RightArrowButton from "./RightArrowButton";
interface ChangePageProps {
  currentPage: number;
  length: number;
  handleChangePage: (direction: "left" | "right") => void;
}
const ChangePage: React.FC<ChangePageProps> = ({
  currentPage,
  length,
  handleChangePage,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LeftArrowButton
        onClick={() => handleChangePage("left")}
        currentPage={currentPage}
      />
      <RightArrowButton
        onClick={() => handleChangePage("right")}
        currentPage={currentPage + 1}
        length={length}
      />
    </Box>
  );
};

export default ChangePage;
