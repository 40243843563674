import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import DeniedAccess from "../components/DeniedAcces";
import {
  BLOG,
  COMPONENT_BACKGROUND_COLOR,
  REVIEW,
  VIDEOS,
} from "../libs/commons/constants";
import {
  formatBlockToHTML,
  generateId,
  getCurrentDateString,
  normalizeTitle,
} from "../libs/commons/utils";
import { addDocument } from "../libs/database/services/Documents.service";
import { sendNewsMail } from "../libs/database/services/Mailer.service";
import { getAllUsers } from "../libs/database/services/Users.service";
interface IsLoggedProp {
  isLogged: boolean;
}

const AddDocument: React.FC<IsLoggedProp> = ({ isLogged }) => {
  const [selectedCollection, setSelectedCollection] = useState("");
  const [documentData, setDocumentData] = useState<any>({});
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleCollectionChange = (event: SelectChangeEvent<string>) => {
    setSelectedCollection(event.target.value);
    setDocumentData({});
  };

  const handleInputChange = (key: string, value: string) => {
    setDocumentData((prevData: any) => ({ ...prevData, [key]: value }));
  };

  const fetchUsersEmails = async (): Promise<string[] | undefined> => {
    const users = await getAllUsers();
    if (users) {
      return users.map((user) => user.email);
    }
    return;
  };

  const handleAddDocument = async () => {
    try {
      if (!selectedCollection) {
        console.error("Selecione uma coleção antes de adicionar o documento.");
        return;
      }

      const commonFields = {
        id: generateId(),
        date: getCurrentDateString(),
        collection: selectedCollection,
        normalizedTitle: normalizeTitle(documentData.title),
      };

      if (documentData.text) {
        documentData.text = formatBlockToHTML(documentData.text);
      }
      const dataToSave = {
        ...commonFields,
        ...documentData,
      };

      await addDocument(dataToSave);
      setSuccessMessage(
        `Documento adcionado com Sucesso a ${selectedCollection}!`
      );

      const userEmails = await fetchUsersEmails();

      if (userEmails) {
        await sendNewsMail(
          userEmails,
          selectedCollection,
          documentData.title,
          commonFields.normalizedTitle
        );
      }

      setErrorMessage(null);
      setDocumentData({});
    } catch (error) {
      console.error("Erro ao adicionar documento:", error);
      setSuccessMessage(null);
      setErrorMessage("Erro ao adicionar o documento. Tente novamente.");
    }
  };

  const renderCollectionFields = () => {
    if (!selectedCollection) return null;

    const commonFields = ["title", "imgSource", "href"];

    if (selectedCollection === VIDEOS) {
      commonFields.push("videoId");
    } else {
      commonFields.push("text");
    }

    return commonFields.map((field) => (
      <TextField
        key={field}
        label={field.charAt(0).toUpperCase() + field.slice(1)}
        multiline={field === "text"}
        variant="outlined"
        value={documentData[field] || ""}
        onChange={(e) => handleInputChange(field, e.target.value)}
        fullWidth
        rows={field === "text" ? 20 : 1}
        margin="normal"
        type={field === "ord" ? "number" : "text"}
      />
    ));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {isLogged ? (
        <Card sx={{ width: "80vw", height: "100%", boxShadow: "0px 5px 5px" }}>
          <CardContent sx={{ fontSize: "large", textAlign: "justify" }}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="collection-select-label" sx={{ bottom: 2 }}>
                Escolha a Coleção
              </InputLabel>
              <Select
                labelId="collection-select-label"
                id="collection-select"
                value={selectedCollection}
                onChange={handleCollectionChange}
                label="Escolha a Coleção"
              >
                {[VIDEOS, REVIEW, BLOG].map((collection) => (
                  <MenuItem key={collection} value={collection}>
                    {collection}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {renderCollectionFields()}

            {errorMessage && (
              <div style={{ color: "red", marginBottom: "1rem" }}>
                {errorMessage}
              </div>
            )}
            {successMessage && (
              <div style={{ color: "green", marginBottom: "1rem" }}>
                {successMessage}
              </div>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDocument}
              sx={{
                backgroundColor: COMPONENT_BACKGROUND_COLOR,
              }}
            >
              Adicionar Documento
            </Button>
          </CardContent>
        </Card>
      ) : (
        <DeniedAccess />
      )}
    </Box>
  );
};

export default AddDocument;
