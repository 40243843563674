import {
  DocumentData,
  Query,
  addDoc,
  collection,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { ViewDoctItem } from "../../interface/ViewDocItem.interface";
import { db } from "../Firebase";

async function getAll(
  queryData: Query<DocumentData, DocumentData>
): Promise<ViewDoctItem[]> {
  const collactionSnap = await getDocs(queryData);

  return collactionSnap.docs.map((doc) => doc.data()) as ViewDoctItem[];
}
async function getCount(
  queryData: Query<DocumentData, DocumentData>
): Promise<number> {
  const collactionSnap = await getCountFromServer(queryData);

  return collactionSnap.data().count;
}

export async function getDocumentsByCollection(
  collectionName: string,
  limitValue: number
): Promise<ViewDoctItem[]> {
  const queryData = query(
    collection(db, "documents"),
    where("collection", "==", collectionName),
    orderBy("date", "desc"),
    limit(limitValue)
  );

  return await getAll(queryData);
}

export async function fetchNextDocumentsByCollection(
  collectionName: string,
  item: ViewDoctItem,
  limitValue: number
): Promise<ViewDoctItem[]> {
  const queryData = query(
    collection(db, "documents"),
    where("collection", "==", collectionName),
    orderBy("date", "desc"),
    startAfter(item.date),
    limit(limitValue)
  );

  return await getAll(queryData);
}

export async function fetchPreviousDocumentsByCollection(
  collectionName: string,
  item: ViewDoctItem,
  limitValue: number
): Promise<ViewDoctItem[]> {
  const queryData = query(
    collection(db, "documents"),
    where("collection", "==", collectionName),
    orderBy("date", "asc"),
    startAfter(item.date),
    limit(limitValue)
  );

  const response = await getAll(queryData);

  return response.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );
}

export async function countAllDocumentsByCollection(
  collectionName: string
): Promise<number> {
  const queryData = query(
    collection(db, "documents"),
    where("collection", "==", collectionName)
  );

  return await getCount(queryData);
}

export async function getDocumentByTitle(
  collectionName: string,
  title: string
): Promise<ViewDoctItem> {
  const queryData = query(
    collection(db, "documents"),
    where("collection", "==", collectionName),
    where("normalizedTitle", "==", title)
  );
  const response = await getAll(queryData);
  return response[0];
}

export async function getAllCollection(
  limitValue: number
): Promise<ViewDoctItem[]> {
  const queryData = query(
    collection(db, "documents"),
    orderBy("date", "desc"),
    limit(limitValue)
  );

  return await getAll(queryData);
}

export async function countAllDocuments(): Promise<number> {
  const queryData = query(collection(db, "documents"));

  return await getCount(queryData);
}
export async function countDocumentsFromCollection(
  collectionName: string,
  title: string
): Promise<number> {
  const queryData = query(
    collection(db, "documents"),
    where("collection", "==", collectionName),
    where("normalizedTitle", "==", title)
  );

  return await getCount(queryData);
}

export async function fetchNextDocuments(
  item: ViewDoctItem,
  limitValue: number
): Promise<ViewDoctItem[]> {
  const queryData = query(
    collection(db, "documents"),
    orderBy("date", "desc"),
    startAfter(item.date),
    limit(limitValue)
  );

  return await getAll(queryData);
}

export async function fetchPreviousDocuments(
  item: ViewDoctItem,
  limitValue: number
): Promise<ViewDoctItem[]> {
  const queryData = query(
    collection(db, "documents"),
    orderBy("date", "asc"),
    startAfter(item.date),
    limit(limitValue)
  );

  const response = await getAll(queryData);

  return response.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );
}

export async function addDocument(data: any): Promise<void> {
  await addDoc(collection(db, "documents"), data);
}
