import { Box, CircularProgress } from "@mui/material";
import React from "react";

const IsLoading: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "3rem",
        marginBottom: "3rem",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default IsLoading;
