import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Banner from "../components/Banner";
import DeniedAccess from "../components/DeniedAcces";
import IsLoading from "../components/IsLoading";
import { ABOUT, COMPONENT_BACKGROUND_COLOR } from "../libs/commons/constants";
import { formatBlockToHTML } from "../libs/commons/utils";
import {
  getAbout,
  updateAbout,
  uploadImage,
} from "../libs/database/services/About.service";
import { AboutItem, SocialMedia } from "../libs/interface/AboutItem.interface";

interface IsLoggedProp {
  isLogged: boolean;
}
const UpdateAbout: React.FC<IsLoggedProp> = ({ isLogged }) => {
  const { data, isLoading, error } = useQuery(ABOUT, async () => {
    return await getAbout();
  });

  const [documentData, setDocumentData] = useState<AboutItem>({
    socialMedia: [{ IconImgSource: "", title: "", url: "" }],
  } as AboutItem);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (data) {
      data.text = data.text
        .split("<p>")
        .map((text: string) => text)
        .join("\n");

      setDocumentData(data);
    }
  }, [data]);

  const handleInputChange = (key: keyof AboutItem, value: string) => {
    setDocumentData((prevData: any) => ({ ...prevData, [key]: value }));
  };

  const handleSocialMediaInputChange = (
    index: number,
    key: keyof SocialMedia,
    value: string
  ) => {
    setDocumentData((prevData) => ({
      ...prevData,
      socialMedia: prevData.socialMedia.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      ),
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  const handleUpdateDocument = async () => {
    if (!documentData.text) {
      setErrorMessage("O campo 'Text' é obrigatório.");
      return;
    }

    try {
      documentData.text = formatBlockToHTML(documentData.text);
      await updateAbout(documentData);

      setDocumentData({
        id: "",
        imgSource: "",
        text: "",
        email: "",
        socialMedia: [],
      });

      setSuccessMessage("'SOBRE' Atualizado com Sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar documento:", error);
      setSuccessMessage(null);
      setErrorMessage("Erro ao atualizar o documento. Tente novamente.");
    }
  };

  const handlelSocialMidiaImageUpload = async (
    index: number,
    key: keyof SocialMedia
  ) => {
    if (image && !isUploading) {
      setIsUploading(true);

      try {
        const imageURL = await uploadImage(image);

        handleSocialMediaInputChange(index, key, imageURL);
      } catch (error) {
        console.error("Erro ao enviar imagem:", error);
        alert("Erro ao enviar imagem. Tente novamente mais tarde.");
      } finally {
        setIsUploading(false);
      }
    } else {
      alert("Nenhuma imagem selecionada.");
    }
  };

  const handlelImageUpload = async (key: keyof AboutItem) => {
    if (image && !isUploading) {
      setIsUploading(true);

      try {
        const imageURL = await uploadImage(image);

        handleInputChange(key, imageURL);
      } catch (error) {
        console.error("Erro ao enviar imagem:", error);
        alert("Erro ao enviar imagem. Tente novamente mais tarde.");
      } finally {
        setIsUploading(false);
      }
    } else {
      alert("Nenhuma imagem selecionada.");
    }
  };

  const handleAddSocialMedia = () => {
    setDocumentData((prevData: AboutItem) => ({
      ...prevData,
      socialMedia: [
        ...prevData.socialMedia,
        {
          title: "",
          IconImgSource: "",
          url: "",
        },
      ],
    }));
  };

  const renderCollectionFields = () => {
    return (
      <>
        <TextField
          label={"ImageSource"}
          variant="outlined"
          value={documentData.imgSource}
          onChange={(e) => handleInputChange("imgSource", e.target.value)}
          fullWidth
          margin="normal"
          type="text"
        />
        <input type="file" onChange={handleFileChange} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlelImageUpload("imgSource")}
          style={{
            marginTop: "1rem",
            backgroundColor: COMPONENT_BACKGROUND_COLOR,
          }}
        >
          Enviar Imagem
        </Button>
        <TextField
          label={"Text"}
          variant="outlined"
          multiline={true}
          value={documentData.text}
          onChange={(e) => handleInputChange("text", e.target.value)}
          fullWidth
          margin="normal"
          type="text"
        />
        <TextField
          label={"Email"}
          variant="outlined"
          value={documentData.email}
          onChange={(e) => handleInputChange("email", e.target.value)}
          fullWidth
          margin="normal"
          type="text"
        />
      </>
    );
  };

  const renderSocialMediaFields = () => {
    return documentData.socialMedia.map((socialMediaItem, index) => (
      <div key={index}>
        <p style={{ fontWeight: "bold" }}>{index + 1}</p>
        <TextField
          label={`Title`}
          variant="outlined"
          value={socialMediaItem.title}
          onChange={(e) =>
            handleSocialMediaInputChange(index, "title", e.target.value)
          }
          fullWidth
          margin="normal"
          type="text"
        />
        <TextField
          label={`IconImgSource`}
          variant="outlined"
          value={socialMediaItem.IconImgSource}
          onChange={(e) =>
            handleSocialMediaInputChange(index, "IconImgSource", e.target.value)
          }
          fullWidth
          margin="normal"
          type="text"
        />
        <input type="file" onChange={handleFileChange} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlelSocialMidiaImageUpload(index, "IconImgSource")}
          style={{
            marginTop: "1rem",
            backgroundColor: COMPONENT_BACKGROUND_COLOR,
          }}
        >
          Enviar Imagem
        </Button>
        <TextField
          label={`URL`}
          variant="outlined"
          value={socialMediaItem.url}
          onChange={(e) =>
            handleSocialMediaInputChange(index, "url", e.target.value)
          }
          fullWidth
          margin="normal"
          type="text"
        />
      </div>
    ));
  };

  if (error) {
    console.error("Error fetching documents:", error);
  }

  if (isLoading) {
    return <IsLoading />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {isLogged ? (
        <Card sx={{ width: "25rem", height: "100%", boxShadow: "0px 5px 5px" }}>
          <Banner title={"Atualizar Sobre"}>
            <CardContent sx={{ fontSize: "large", textAlign: "justify" }}>
              {renderCollectionFields()}
              <p style={{ fontWeight: "bold" }}>Midias Sociais</p>
              {renderSocialMediaFields()}
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddSocialMedia}
                style={{
                  marginTop: "1rem",
                  backgroundColor: COMPONENT_BACKGROUND_COLOR,
                }}
              >
                Adicionar Social Media
              </Button>
              <p />

              {errorMessage && (
                <div style={{ color: "red", marginBottom: "1rem" }}>
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div style={{ color: "green", marginBottom: "1rem" }}>
                  {successMessage}
                </div>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateDocument}
                sx={{
                  backgroundColor: COMPONENT_BACKGROUND_COLOR,
                }}
              >
                Atualizar Texto
              </Button>
            </CardContent>
          </Banner>
        </Card>
      ) : (
        <DeniedAccess />
      )}
    </Box>
  );
};

export default UpdateAbout;
