import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import CheckCode from "../components/CheckCode";
import { COMPONENT_BACKGROUND_COLOR } from "../libs/commons/constants";
import { createCode } from "../libs/database/services/Subscribe.service";
import { removeUser } from "../libs/database/services/Users.service";
import ReCAPTCHA from "react-google-recaptcha";
import { StyledCenterContainer } from "../App";
import { useWindowWidth } from "../libs/commons/useWindowWidth";

const UnSubscribe: React.FC = () => {
  const isMobile = useWindowWidth();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [emailData, setEmailData] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleUnSubscribe = async () => {
    if (!captchaValue) {
      setErrorMessage(
        "Por favor, resolva o captcha para adicionar um comentário."
      );
      return;
    }

    if (!validateEmail(emailData)) {
      setErrorMessage("Insira um endereço de email válido!");
      return;
    }

    try {
      await createCode(emailData);
      setErrorMessage(null);
      setShowConfirm(true);
    } catch (error) {
      console.log("Error", error);
      setErrorMessage("Algo Inesperado Ocorreu, tente novamente mais tarde.");
    }
  };

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const handleInputChange = (value: string) => {
    setEmailData(value);
  };

  const validateEmail = (email: string) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
  };

  const unSubscribeUser = async () => {
    await removeUser(emailData);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showConfirm]);

  const renderSubescribe = () => {
    return (
      <Card
        sx={{
          maxWidth: isMobile ? "100%" : "50%",
          minWidth: "25%",
          height: "100%",
          boxShadow: "0px 5px 5px",
        }}
      >
        <Banner title={"deixar de seguir"} />
        <CardContent sx={{ fontSize: "large", textAlign: "justify" }}>
          <Typography variant="body2">
            <div className="home-container">
              <p style={{ fontWeight: "bold" }}>Insira o email:</p>
              <TextField
                label={"Email"}
                variant="outlined"
                value={emailData}
                onChange={(e) => handleInputChange(e.target.value)}
                fullWidth
                margin="normal"
                type="text"
              />
            </div>
          </Typography>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
            onChange={handleCaptchaChange}
          />
          {errorMessage && (
            <div style={{ color: "red", marginBottom: "1rem" }}>
              {errorMessage}
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleUnSubscribe}
            sx={{
              backgroundColor: COMPONENT_BACKGROUND_COLOR,
            }}
          >
            Deixar de Seguir
          </Button>
        </CardContent>
      </Card>
    );
  };

  return (
    <StyledCenterContainer>
      {showConfirm ? (
        <StyledCenterContainer>
          <CheckCode email={emailData} onConfirm={unSubscribeUser} />
        </StyledCenterContainer>
      ) : (
        renderSubescribe()
      )}
    </StyledCenterContainer>
  );
};

export default UnSubscribe;
