import { doc, getDoc, setDoc } from "firebase/firestore";
import { generateCode } from "../../commons/utils";
import { SubscribeInterface } from "../../interface/Subscribe.interface";
import { db } from "../Firebase";
import { sendSubscribeMail } from "./Mailer.service";

export async function checkSubscribeCode(
  email: string
): Promise<SubscribeInterface> {
  const response = await getDoc(doc(db, "subscribe", email));
  return response.data() as SubscribeInterface;
}

export async function createCode(email: string): Promise<void> {
  const code = generateCode();
  await setDoc(doc(db, "subscribe", email), { email, code });
  await sendSubscribeMail(email, code);
}
