import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { getCurrentDateString } from "../libs/commons/utils";
import { COMPONENT_BACKGROUND_COLOR } from "../libs/commons/constants";
import { addComment } from "../libs/database/services/Comments.service";
import { notifyCommentMail } from "../libs/database/services/Mailer.service";
import { ViewDoctItem } from "../libs/interface/ViewDocItem.interface";

interface AddCommentProps {
  item: ViewDoctItem;
  onCommentAdded: () => void;
}

const AddComments: React.FC<AddCommentProps> = ({ item, onCommentAdded }) => {
  const [documentData, setDocumentData] = useState<any>({});
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleInputChange = (key: string, value: string) => {
    setDocumentData((prevData: any) => ({ ...prevData, [key]: value }));
  };

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const handleAddDocument = async () => {
    if (!captchaValue) {
      setErrorMessage(
        "Por favor, resolva o captcha para adicionar um comentário."
      );
      return;
    }

    const commonFields = {
      idRef: item.id,
      date: getCurrentDateString(),
      collection: item.collection,
    };
    if (!documentData.text) {
      setErrorMessage("O campo 'Text' é obrigatório.");
      return;
    }

    try {
      await addComment({
        ...commonFields,
        ...documentData,
      });

      await notifyCommentMail(
        item.collection,
        item.title,
        item.normalizedTitle
      );

      onCommentAdded();

      setDocumentData({});
      setSuccessMessage(`Comentário adcionado!`);
      setErrorMessage(null);
    } catch (error) {
      console.error("Erro ao adicionar documento:", error);
      setSuccessMessage(null);
      setErrorMessage("Erro ao adicionar o comentário. Tente novamente.");
    }
  };

  const renderCollectionFields = () => {
    const commonFields = ["author", "text"];

    return commonFields.map((field) => (
      <TextField
        key={field}
        label={field.charAt(0).toUpperCase() + field.slice(1)}
        variant="outlined"
        value={documentData[field] || ""}
        onChange={(e) => handleInputChange(field, e.target.value)}
        fullWidth
        margin="normal"
        type="text"
      />
    ));
  };

  return (
    <Box
      sx={{
        marginBottom: 2,
        cursor: "pointer",
        boxShadow: "5px 5px 5px",
      }}
    >
      <Card sx={{ width: "100%", height: "100%", backgroundColor: grey[200] }}>
        <CardContent sx={{ fontSize: "large", textAlign: "justify" }}>
          {renderCollectionFields()}
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
            onChange={handleCaptchaChange}
          />

          {errorMessage && (
            <div style={{ color: "red", marginBottom: "1rem" }}>
              {errorMessage}
            </div>
          )}
          {successMessage && (
            <div style={{ color: "green", marginBottom: "1rem" }}>
              {successMessage}
            </div>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleAddDocument}
            sx={{
              backgroundColor: COMPONENT_BACKGROUND_COLOR,
            }}
          >
            Adicionar Comentario
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AddComments;
