import { Card, Box } from "@mui/material";
import React from "react";
import Banner from "./Banner";

const DeniedAccess: React.FC = () => {
  return (
    <Card sx={{ width: "25rem", height: "100%", boxShadow: "0px 5px 5px" }}>
      <Box
        sx={{
          marginTop: "3rem",
          marginBottom: "3rem",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Banner title={"VOCE PRECISA ESTAR LOGADO"} color={"red"} />
      </Box>
    </Card>
  );
};

export default DeniedAccess;
