import { CssBaseline, ThemeProvider, createTheme, styled } from "@mui/material";
import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  ABOUT,
  ADD,
  ADMIN,
  BACKGROUND_COLOR,
  BLOG,
  HOME,
  REVIEW,
  SUBSCRIBE,
  UNSUBSCRIBE,
  UPDATE,
  VIDEOS,
} from "./libs/commons/constants";
import { buildPath } from "./libs/commons/utils";
import { ViewDoctItem } from "./libs/interface/ViewDocItem.interface";
import About from "./pages/About";
import AddDocument from "./pages/AddDocument";
import AdminPage from "./pages/AdminPage";
import BottomBar from "./pages/BottomBar";
import DirectViewDocs from "./pages/DirectViewDocs";
import Header from "./pages/Header";
import Home from "./pages/Home";
import Main from "./pages/Main";
import Subscribe from "./pages/Subscribe";
import UnSubscribe from "./pages/UnSubscribe";
import UpdateAbout from "./pages/UpdateAbout";
import ViewDocument from "./pages/ViewDocument";

const theme = createTheme();
const StyledAppContainer = styled("div")({
  marginTop: "1vw",
  backgroundColor: BACKGROUND_COLOR,
});

const limit = 6;

export const StyledCenterContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const App: React.FC = () => {
  const url = window.location.href;
  const urlArray = url.split("/");

  useEffect(() => {}, []);

  const [selectedItem, setSelectedItem] = React.useState<ViewDoctItem>(
    {} as ViewDoctItem
  );

  const [isLogged, setIsLogged] = React.useState<boolean>(false);
  const [path] = React.useState<string | undefined>(
    urlArray.length > 4 ? `${urlArray[3]}/${urlArray[4]}` : undefined
  );
  const [collection] = React.useState<string>(
    urlArray.length > 3 ? urlArray[3] : ""
  );
  const [title] = React.useState<string>(
    urlArray.length > 4 ? urlArray[4] : ""
  );

  const openItem = (item: ViewDoctItem) => {
    setSelectedItem(item);
  };

  const onLogged = (isLogged: boolean) => {
    setIsLogged(isLogged);
  };

  return (
    <div style={{ backgroundColor: BACKGROUND_COLOR }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <StyledAppContainer>
          <Routes>
            <Route
              path={buildPath(HOME)}
              element={<Home limit={limit} openItem={openItem} />}
            />
            <Route
              path={buildPath(REVIEW)}
              element={
                <Main limit={limit} collection={REVIEW} openItem={openItem} />
              }
            />
            <Route
              path={buildPath(VIDEOS)}
              element={
                <Main limit={limit} collection={VIDEOS} openItem={openItem} />
              }
            />
            <Route
              path={buildPath(BLOG)}
              element={
                <Main limit={limit} collection={BLOG} openItem={openItem} />
              }
            />

            <Route path={buildPath(ABOUT)} element={<About />} />
            <Route path={buildPath(SUBSCRIBE)} element={<Subscribe />} />
            <Route path={buildPath(UNSUBSCRIBE)} element={<UnSubscribe />} />
            <Route
              path={buildPath(
                `${selectedItem.collection}/${selectedItem.normalizedTitle}`
              )}
              element={<ViewDocument item={selectedItem} />}
            />
            {path ? (
              <Route
                path={buildPath(path)}
                element={
                  <DirectViewDocs collection={collection} title={title} />
                }
              />
            ) : null}

            <Route
              path={buildPath(ADMIN)}
              element={<AdminPage onLogged={onLogged} isLogged={isLogged} />}
            />
            <Route
              path={buildPath(ADD)}
              element={<AddDocument isLogged={isLogged} />}
            />
            <Route
              path={buildPath(UPDATE)}
              element={<UpdateAbout isLogged={isLogged} />}
            />
            <Route path="/" element={<Navigate to={buildPath(HOME)} />} />
            <Route path="*" element={<Navigate to={buildPath(HOME)} />} />
          </Routes>
          <BottomBar />
        </StyledAppContainer>
      </ThemeProvider>
    </div>
  );
};

export default App;
