import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { CommentDocItem } from "../../interface/CommentDocItem.interface";
import { ViewDoctItem } from "../../interface/ViewDocItem.interface";
import { db } from "../Firebase";

export async function getCommentDocs(
  item: ViewDoctItem
): Promise<CommentDocItem[]> {
  const collectionData = query(
    collection(db, "comments"),
    where("collection", "==", item.collection),
    where("idRef", "==", item.id),
    orderBy("date", "desc")
  );
  const collactionSnap = await getDocs(collectionData);

  return collactionSnap.docs.map((doc) => doc.data()) as CommentDocItem[];
}

export async function addComment(data: any): Promise<void> {
  await addDoc(collection(db, "comments"), data);
}
