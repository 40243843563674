import { IconButton } from "@mui/material";
import React from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

interface LeftArrowButtonProps {
  onClick: () => void;
  currentPage: number;
}

const LeftArrowButton: React.FC<LeftArrowButtonProps> = ({
  onClick,
  currentPage,
}) => {
  return (
    <IconButton
      data-testid="left-arrow-button"
      onClick={() => onClick()}
      sx={{ margin: 5, color: "white", transform: "scale(5)" }}
      disabled={currentPage === 0}
    >
      <NavigateBeforeIcon />
    </IconButton>
  );
};

export default LeftArrowButton;
