import { Box, Button, Card, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { COMPONENT_BACKGROUND_COLOR } from "../libs/commons/constants";
import { checkSubscribeCode } from "../libs/database/services/Subscribe.service";
import Banner from "./Banner";

interface CheckCodeProps {
  email: string;
  onConfirm: () => Promise<void>;
}

const CheckCode: React.FC<CheckCodeProps> = ({ email, onConfirm }) => {
  const [values, setValues] = useState(["", "", "", "", ""]);

  const [countdown, setCountdown] = useState(10);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);

    if (value.length === 1 && index < values.length - 1) {
      const nextIndex = index + 1;
      const nextInput = document.getElementById(`input-${nextIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  const handleConfirm = async () => {
    try {
      const code = values.join("");

      const subscription = await checkSubscribeCode(email);

      console.log("response", subscription);

      if (!subscription) {
        setErrorMessage("Código inválido.");
        return;
      }

      if (subscription.code !== code) {
        setErrorMessage("Código não confere com o email fornecido!");
        return;
      }

      onConfirm();

      setValues(["", "", "", "", ""]);
      setSuccessMessage(`Código confirmado!`);
      setErrorMessage(null);
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Erro:", error);
      setSuccessMessage(null);
      setErrorMessage("Erro. Tente novamente.");
    }
  };

  useEffect(() => {
    let timer: any;
    if (countdown > 0 && isButtonDisabled) {
      timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsButtonDisabled(false);
    }

    return () => clearTimeout(timer);
  }, [countdown, isButtonDisabled, refresh]);

  return (
    <Card sx={{ width: "25rem", height: "100%", boxShadow: "0px 5px 5px" }}>
      <Box
        sx={{
          margin: "1rem",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Banner title={"confirme o código"}>
          <Typography
            variant="body2"
            sx={{
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            Código enviado ao email {email}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {values.map((value, index) => (
              <TextField
                key={index}
                type="text"
                variant="outlined"
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center" },
                }}
                style={{ width: "calc(20% - 1rem)" }}
                value={value}
                onChange={(e) => handleInputChange(index, e)}
                id={`input-${index}`}
              />
            ))}
          </Box>
          {errorMessage && (
            <div style={{ color: "red", marginBottom: "1rem" }}>
              {errorMessage}
            </div>
          )}
          {successMessage && (
            <div style={{ color: "green", marginBottom: "1rem" }}>
              {successMessage}
            </div>
          )}
          <Box
            sx={{
              margin: "1rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              sx={{
                backgroundColor: COMPONENT_BACKGROUND_COLOR,
              }}
            >
              Confirmar
            </Button>
            {/* <Button
              variant="contained"
              color="primary"
              disabled={isButtonDisabled}
              onClick={handleReSubmit}
              sx={{
                backgroundColor: COMPONENT_BACKGROUND_COLOR,
              }}
            >
              {isButtonDisabled ? `Aguarde: ${countdown}` : "Reenviar"}
            </Button> */}
          </Box>
        </Banner>
      </Box>
    </Card>
  );
};

export default CheckCode;
