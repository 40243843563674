export const ABOUT = "sobre";
export const ADMIN = "tocaadminlogin";
export const ADD = "add";
export const BLOG = "blog";
export const COMMENTS = "comentarios";
export const HOME = "home";
export const LOGIN = "login";
export const NEW_POST = "adicionar";
export const SUBSCRIBE = "inscreva-se";
export const REVIEW = "reviews";
export const UNSUBSCRIBE = "unsubscribe";
export const UPDATE = "update";
export const VIDEOS = "videos";
export const VIEW = "view_page";
export const LOGO_BACKGROUND_COLOR = "#001F3D";
export const COMPONENT_BACKGROUND_COLOR = "#0F4A6F";
export const BACKGROUND_COLOR = "#CCDADA";
