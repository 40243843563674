import Box from "@mui/material/Box";
import React from "react";
import { useWindowWidth } from "../libs/commons/useWindowWidth";
import { ViewDoctItem } from "../libs/interface/ViewDocItem.interface";
import CardBuilder from "./CardBuilder";
import LeftArrowButton from "./LeftArrowButton";
import RightArrowButton from "./RightArrowButton";

const cardsPerPage = 1;

interface CarouselProps {
  items: ViewDoctItem[];
  openItem: (item: ViewDoctItem) => void;
}

const Carousel: React.FC<CarouselProps> = ({ items, openItem }) => {
  const [startX, setStartX] = React.useState<number | null>(null);
  const [endX, setEndX] = React.useState<number | null>(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [slideDirection, setSlideDirection] = React.useState<
    "right" | "left" | undefined
  >("left");

  const handleTouchStart = (event: React.TouchEvent) => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    setEndX(event.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (startX !== null && endX !== null) {
      const deltaX = endX - startX;

      if (Math.abs(deltaX) > 50) {
        if (deltaX > 0) {
          handleSwipe("right");
        } else {
          handleSwipe("left");
        }
      }

      setStartX(null);
      setEndX(null);
    }
  };

  const handleSwipe = (direction: "left" | "right") => {
    setSlideDirection(direction);
    setCurrentPage((prevPage) => {
      if (direction === "left") {
        return Math.min(prevPage + 1, items.length - 1);
      } else {
        return Math.max(prevPage - 1, 0);
      }
    });
  };

  const isMobile = useWindowWidth();

  return (
    <Box
      data-testid="carousel-box"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "1rem",
        marginBottom: "2rem",
      }}
    >
      {isMobile ? (
        <>
          <CardBuilder
            items={items}
            currentPage={currentPage}
            slideDirection={slideDirection}
            openItemView={openItem}
            cardsPerPage={cardsPerPage}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LeftArrowButton
              onClick={() => handleSwipe("right")}
              currentPage={currentPage}
            />
            <RightArrowButton
              onClick={() => handleSwipe("left")}
              currentPage={currentPage}
              length={items.length}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LeftArrowButton
            onClick={() => handleSwipe("right")}
            currentPage={currentPage}
          />
          <CardBuilder
            items={items}
            currentPage={currentPage}
            slideDirection={slideDirection}
            openItemView={openItem}
            cardsPerPage={cardsPerPage}
          />
          <RightArrowButton
            onClick={() => handleSwipe("left")}
            currentPage={currentPage}
            length={items.length}
          />
        </Box>
      )}
    </Box>
  );
};

export default Carousel;
