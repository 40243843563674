import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { UserInterface } from "../../interface/User.interface";
import { db } from "../Firebase";

export async function getAllUsers(): Promise<UserInterface[]> {
  const collactionSnap = await getDocs(collection(db, "users"));

  return collactionSnap.docs.map((doc) => doc.data()) as UserInterface[];
}

export async function getUserByEmail(email: string): Promise<UserInterface> {
  return (await getDoc(doc(db, "users", email))) as unknown as UserInterface;
}

export async function addUser(email: string): Promise<void> {
  await setDoc(doc(db, "users", email), { email });
}

export async function removeUser(email: string): Promise<void> {
  await deleteDoc(doc(db, "users", email));
}
