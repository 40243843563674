import React, { useEffect } from "react";
import { useQuery } from "react-query";
import IsLoading from "../components/IsLoading";
import { getDocumentByTitle } from "../libs/database/services/Documents.service";
import { ViewDoctItem } from "../libs/interface/ViewDocItem.interface";
import ViewDocument from "./ViewDocument";

interface ViewDocProps {
  collection: string;
  title: string;
}

const DirectViewDocs: React.FC<ViewDocProps> = ({ collection, title }) => {
  const { data, isLoading, error } = useQuery(
    collection + title,
    async () => {
      return await getDocumentByTitle(collection, title);
    },
    {
      staleTime: 6000 * 50,
      cacheTime: 6000 * 50,
    }
  );

  useEffect(() => {}, []);

  if (error) {
    console.error("Error fetching documents:", error);
  }

  if (isLoading) {
    return <IsLoading />;
  }

  return <ViewDocument item={data ?? ({} as ViewDoctItem)} />;
};

export default DirectViewDocs;
