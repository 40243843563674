import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import TaskBar from "../components/TaskBar";
import TaskBarMobile from "../components/TaskBarMobile";
import bannerImage from "../images/logo/bannerImage.png";
import {
  ABOUT,
  BLOG,
  HOME,
  LOGO_BACKGROUND_COLOR,
  REVIEW,
  VIDEOS,
} from "../libs/commons/constants";
import { useWindowWidth } from "../libs/commons/useWindowWidth";
import { buildPath } from "../libs/commons/utils";

const Header: React.FC = () => {
  const array = [HOME, REVIEW, VIDEOS, BLOG, ABOUT];

  const isMobile = useWindowWidth();

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{
          backgroundColor: LOGO_BACKGROUND_COLOR,
          width: "100%",
        }}
      >
        <Link
          to={buildPath(HOME)}
          style={{ textDecoration: "none", color: "white" }}
        >
          <img
            src={bannerImage}
            alt="Logo da Página"
            style={{ height: "8vw", maxHeight: "8vw" }}
          />
        </Link>
      </Box>
      {isMobile ? <TaskBarMobile array={array} /> : <TaskBar array={array} />}
    </>
  );
};

export default Header;
