import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db, firebaseStorage } from "../Firebase";
import { AboutItem } from "../../interface/AboutItem.interface";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export async function updateAbout(data: any): Promise<void> {
  await updateDoc(doc(db, "about", data.id), data);
}

export async function uploadImage(data: File): Promise<string> {
  const storageRef = ref(firebaseStorage, `images/${data.name}`);

  await uploadBytes(storageRef, data);

  return await getDownloadURL(storageRef);
}

export async function addAbout(data: AboutItem): Promise<void> {
  await addDoc(collection(db, "about"), data);
}

export async function getAbout(): Promise<AboutItem> {
  const collactionSnap = await getDocs(collection(db, "about"));

  return collactionSnap.docs.map((doc) => doc.data())[0] as AboutItem;
}
