import React from "react";
import { useQuery } from "react-query";
import HomeComponent from "../components/HomeComponent";
import IsLoading from "../components/IsLoading";
import { HOME } from "../libs/commons/constants";
import { countAllDocuments } from "../libs/database/services/Documents.service";
import { ViewDoctItem } from "../libs/interface/ViewDocItem.interface";
import SubscribeButton from "../components/SubscribeButton";
interface HomeProps {
  limit: number;
  openItem: (item: ViewDoctItem) => void;
}
const Home: React.FC<HomeProps> = ({ limit, openItem }) => {
  const { data, isLoading, error } = useQuery(
    `count${HOME}`,
    async () => {
      return await countAllDocuments();
    },
    {
      staleTime: 6000 * 50,
      cacheTime: 6000 * 50,
    }
  );

  if (error) {
    console.error("Error fetching documents:", error);
  }

  if (isLoading) {
    return <IsLoading />;
  }

  return (
    <>
      <SubscribeButton />
      <HomeComponent limit={limit} openItem={openItem} count={data ?? 0} />
    </>
  );
};

export default Home;
