import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton } from "@mui/material";
import React from "react";

interface RightArrowButtonProps {
  onClick: () => void;
  currentPage: number;
  length: number;
}

const RightArrowButton: React.FC<RightArrowButtonProps> = ({
  onClick,
  currentPage,
  length,
}) => {
  return (
    <IconButton
      data-testid="right-arrow-button"
      onClick={() => onClick()}
      sx={{
        margin: 5,
        transform: "scale(5)",
        color: "white",
      }}
      disabled={currentPage >= length}
    >
      <NavigateNextIcon />
    </IconButton>
  );
};

export default RightArrowButton;
