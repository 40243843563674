import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useQuery } from "react-query";
import CheckCode from "../components/CheckCode";
import IsLoading from "../components/IsLoading";
import logoImage from "../images/logo/logofundo.png";
import { ABOUT, COMPONENT_BACKGROUND_COLOR } from "../libs/commons/constants";
import { getAbout } from "../libs/database/services/About.service";
import { createCode } from "../libs/database/services/Subscribe.service";
import { addUser } from "../libs/database/services/Users.service";
import { StyledCenterContainer } from "../App";
import { useWindowWidth } from "../libs/commons/useWindowWidth";

const Subscribe: React.FC = () => {
  const isMobile = useWindowWidth();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [emailData, setEmailData] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { data, isLoading, error } = useQuery(
    ABOUT,
    async () => {
      return await getAbout();
    },
    {
      staleTime: 3600000,
      cacheTime: 3600000,
    }
  );

  const createNewUser = async () => {
    await addUser(emailData);
  };

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const handleSubscribe = async () => {
    if (!captchaValue) {
      setErrorMessage(
        "Por favor, resolva o captcha para adicionar um comentário."
      );
      return;
    }

    if (!validateEmail(emailData)) {
      setErrorMessage("Insira um endereço de email válido!");
      return;
    }
    try {
      await createCode(emailData);
      setErrorMessage(null);
      setShowConfirm(true);
    } catch (error) {
      console.log("Error", error);
      setErrorMessage("Algo Inesperado Ocorreu, tente novamente mais tarde.");
    }
  };

  const handleInputChange = (value: string) => {
    setEmailData(value);
  };

  const validateEmail = (email: string) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showConfirm]);

  if (error) {
    console.error("Error fetching documents:", error);
  }

  if (isLoading) {
    return <IsLoading />;
  }

  const renderSubescribe = () => {
    return (
      <Card
        sx={{
          maxWidth: isMobile ? "100%" : "50%",
          height: "100%",
          boxShadow: "0px 5px 5px",
        }}
      >
        <CardMedia
          component="img"
          height="180"
          image={data?.imgSource ?? logoImage}
          style={{
            marginTop: "10px",
            maxWidth: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <CardContent sx={{ fontSize: "large", textAlign: "justify" }}>
          <Typography variant="body2">
            <div className="home-container">
              <p style={{ fontWeight: "bold" }}>
                Você pode se inscrever na Toca do Jota nas redes Sociais
              </p>
              <>
                {data?.socialMedia.map((socialMedia) => (
                  <Grid container spacing={1} alignItems="center">
                    {socialMedia.IconImgSource && (
                      <Grid item>
                        <a
                          href={socialMedia.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={socialMedia.IconImgSource}
                            alt={`${socialMedia.title} favicon`}
                            width="32"
                            height="32"
                          />
                        </a>
                      </Grid>
                    )}
                    <Grid item>
                      <a
                        href={socialMedia.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {socialMedia.title}
                      </a>
                    </Grid>
                  </Grid>
                ))}
              </>
              <p style={{ fontWeight: "bold" }}>Ou pelo email:</p>
              <TextField
                label={"Email"}
                variant="outlined"
                value={emailData}
                onChange={(e) => handleInputChange(e.target.value)}
                fullWidth
                margin="normal"
                type="text"
              />
            </div>
          </Typography>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
            onChange={handleCaptchaChange}
          />
          {errorMessage && (
            <div style={{ color: "red", marginBottom: "1rem" }}>
              {errorMessage}
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubscribe}
            sx={{
              backgroundColor: COMPONENT_BACKGROUND_COLOR,
            }}
          >
            Inscrever
          </Button>
        </CardContent>
      </Card>
    );
  };

  return (
    <StyledCenterContainer>
      {showConfirm ? (
        <CheckCode email={emailData} onConfirm={createNewUser} />
      ) : (
        renderSubescribe()
      )}
    </StyledCenterContainer>
  );
};

export default Subscribe;
