import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { buildPath } from "../libs/commons/utils";
import { ViewDoctItem } from "../libs/interface/ViewDocItem.interface";
import CardItem from "./CardItem";
import { useWindowWidth } from "../libs/commons/useWindowWidth";

interface GridViewProps {
  itemsPerRow: number;
  items: ViewDoctItem[];
  openItem: (item: ViewDoctItem) => void;
}

const GridView: React.FC<GridViewProps> = ({
  itemsPerRow,
  items,
  openItem,
}) => {
  const isMobile = useWindowWidth();
  useEffect(() => {}, [items]);
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: isMobile ? "1fr" : `repeat(${itemsPerRow}, 1fr)`,
        gap: 10,
        maxWidth: isMobile ? "100%" : "80%",
        marginTop: "1rem",
        marginBottom: "2rem",
        alignItems: "center",
      }}
    >
      {items.map((item, index) => (
        <Box
          key={index}
          data-testid={`grid-item-${index}`}
          sx={{
            marginBottom: 2,
            cursor: "pointer",
            width: isMobile ? "80vw" : "30vw",
            height: isMobile ? "60vh" : "60vh",
            marginLeft: "auto",
            marginRight: "auto",
            boxShadow: "5px 5px 5px",
          }}
          onClick={() => openItem(item)}
        >
          <Link
            key={item.id}
            to={buildPath(`${item.collection}/${item.normalizedTitle}`)}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CardItem item={item} />
          </Link>
        </Box>
      ))}
    </Box>
  );
};

export default GridView;
