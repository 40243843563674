import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Carousel from "../components/Carousel";
import GridView from "../components/GridView";
import IsLoading from "../components/IsLoading";
import {
  fetchNextDocumentsByCollection,
  fetchPreviousDocumentsByCollection,
  getDocumentsByCollection,
} from "../libs/database/services/Documents.service";
import { ViewDoctItem } from "../libs/interface/ViewDocItem.interface";
import ChangePage from "./ChangePage";

interface MainComponentProps {
  limit: number;
  collection: string;
  count: number;
  openItem: (item: ViewDoctItem) => void;
}

const MainComponent: React.FC<MainComponentProps> = ({
  limit,
  collection,
  count,
  openItem,
}) => {
  const { data, isLoading, error } = useQuery(
    collection,
    async () => {
      return await getDocumentsByCollection(collection, limit);
    },
    {
      staleTime: 6000 * 50,
      cacheTime: 6000 * 50,
    }
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [items, setItems] = useState<ViewDoctItem[]>(
    [] as unknown as ViewDoctItem[]
  );
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (items.length <= 0) {
      setItems(data ?? ([] as unknown as ViewDoctItem[]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, refresh, collection]);

  const handleChangePage = async (direction: "left" | "right") => {
    setCurrentPage((prevPage) => {
      if (direction === "right") {
        return Math.min(prevPage + 1, items.length - 1);
      } else {
        return Math.max(prevPage - 1, 0);
      }
    });

    const response =
      direction === "right"
        ? await fetchNextDocumentsByCollection(
            collection,
            items[items.length - 1],
            limit
          )
        : await fetchPreviousDocumentsByCollection(collection, items[0], limit);

    setItems(response);
    setRefresh((prev) => !prev);
  };

  if (error) {
    console.error("Error fetching documents:", error);
  }

  if (isLoading) {
    return <IsLoading />;
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Box overflow="hidden">
        <Carousel items={data?.slice(0, 3) ?? []} openItem={openItem} />
        <GridView itemsPerRow={2} items={data ?? []} openItem={openItem} />
        {count > limit && (
          <ChangePage
            currentPage={currentPage}
            length={items.length}
            handleChangePage={handleChangePage}
          />
        )}
      </Box>
    </Stack>
  );
};

export default MainComponent;
