import { Box, Typography } from "@mui/material";
import React from "react";
import { COMPONENT_BACKGROUND_COLOR } from "../libs/commons/constants";

interface BannerProps {
  title: string;
  children?: React.ReactNode;
  color?: string;
}

const Banner: React.FC<BannerProps> = ({ title, children, color }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "2rem",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography
        data-testid="section-text"
        variant="h4"
        gutterBottom
        style={{
          textAlign: "center",
          backgroundColor: color ?? COMPONENT_BACKGROUND_COLOR,
          color: "#FFFFFF",
          fontWeight: "bold",
          fontSize: "1.5rem",
          padding: "8px",
          borderRadius: "4px",
          width: "100%",
        }}
      >
        {title.toUpperCase()}
      </Typography>
      {children}
    </Box>
  );
};

export default Banner;
