import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import { COMPONENT_BACKGROUND_COLOR, LOGIN } from "../libs/commons/constants";
import { login } from "../libs/database/services/Login.service";
import { StyledCenterContainer } from "../App";

interface LoginProps {
  isLogged: boolean;
  children: React.ReactNode;
  onLogged: (bool: boolean) => void;
}

const Login: React.FC<LoginProps> = ({ isLogged, children, onLogged }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await login(username, password);

      onLogged(true);
      setLoggedIn(true);
      setError(null);
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      setError("Usuário/senha inválidos");
    }
  };

  useEffect(() => {
    if (isLogged) {
      setLoggedIn(true);
    }
  }, [isLogged]);

  return (
    <StyledCenterContainer>
      {loggedIn || isLogged ? (
        <Card sx={{ width: "25rem", height: "100%", boxShadow: "0px 5px 5px" }}>
          <>{children}</>
        </Card>
      ) : (
        <Card sx={{ width: "25rem", height: "100%", boxShadow: "0px 5px 5px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Banner title={LOGIN}>
              {error && (
                <Typography color="error" variant="subtitle1" gutterBottom>
                  {error}
                </Typography>
              )}
              <CardContent sx={{ fontSize: "large", textAlign: "justify" }}>
                <TextField
                  label="Username"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogin}
                  sx={{
                    backgroundColor: COMPONENT_BACKGROUND_COLOR,
                  }}
                >
                  Login
                </Button>
              </CardContent>
            </Banner>
          </Box>
        </Card>
      )}
    </StyledCenterContainer>
  );
};

export default Login;
