import { Box, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Login from "../components/Login";
import {
  ADD,
  COMPONENT_BACKGROUND_COLOR,
  UPDATE,
} from "../libs/commons/constants";
import { buildPath } from "../libs/commons/utils";
interface AdminProps {
  isLogged: boolean;
  onLogged: (bool: boolean) => void;
}
const AdminPage: React.FC<AdminProps> = ({ onLogged, isLogged }) => {
  return (
    <Login onLogged={onLogged} isLogged={isLogged}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: "2rem",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Link
          to={buildPath(ADD)}
          style={{ textDecoration: "none", color: "white" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: "1rem",
              backgroundColor: COMPONENT_BACKGROUND_COLOR,
            }}
          >
            Adicionar Texto
          </Button>
        </Link>
        <Link
          to={buildPath(UPDATE)}
          style={{ textDecoration: "none", color: "white" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: "1rem",
              backgroundColor: COMPONENT_BACKGROUND_COLOR,
            }}
          >
            Atualizar SOBRE
          </Button>
        </Link>
      </Box>
    </Login>
  );
};

export default AdminPage;
