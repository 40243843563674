import { Box, Slide, Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { buildPath } from "../libs/commons/utils";
import { ViewDoctItem } from "../libs/interface/ViewDocItem.interface";
import CardItem from "./CardItem";
import { useWindowWidth } from "../libs/commons/useWindowWidth";

interface CardBuilderProps {
  items: ViewDoctItem[];
  currentPage: number;
  cardsPerPage: number;
  slideDirection: "right" | "left" | undefined;
  openItemView: (item: ViewDoctItem) => void;
}

const CardBuilder: React.FC<CardBuilderProps> = ({
  items,
  currentPage,
  cardsPerPage,
  slideDirection,
  openItemView,
}) => {
  const isMobile = useWindowWidth();
  return (
    <>
      {items.map((item, index) => (
        <Box
          data-testid={`card-${index}`}
          key={`card-${index}`}
          sx={{
            display: currentPage === index ? "block" : "none",
          }}
          onClick={() => openItemView(item)}
        >
          <Slide direction={slideDirection} in={currentPage === index}>
            <Stack
              spacing={2}
              direction="row"
              alignContent="center"
              justifyContent="center"
              sx={{
                width: isMobile ? "80vw" : "45vw",
                height: isMobile ? "100vh" : "60vh",
              }}
            >
              {items
                .slice(
                  index * cardsPerPage,
                  index * cardsPerPage + cardsPerPage
                )
                .map((item: ViewDoctItem) => (
                  <Box
                    key={item.id}
                    sx={{
                      marginBottom: 2,
                      cursor: "pointer",
                      width: isMobile ? "80vw" : "50vw",
                      boxShadow: "5px 5px 5px",
                    }}
                  >
                    <Link
                      key={item.id}
                      to={buildPath(
                        `${item.collection}/${item.normalizedTitle}`
                      )}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <CardItem item={item} />
                    </Link>
                  </Box>
                ))}
            </Stack>
          </Slide>
        </Box>
      ))}
    </>
  );
};

export default CardBuilder;
