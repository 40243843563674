import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../images/logo/logo.png";
import { COMPONENT_BACKGROUND_COLOR } from "../libs/commons/constants";
import { buildPath } from "../libs/commons/utils";

interface IconButtonItemProps {
  selected: string;
}
const IconButtonItem: React.FC<IconButtonItemProps> = ({ selected }) => {
  return (
    <Link
      to={buildPath(selected)}
      style={{ textDecoration: "none", color: "white" }}
    >
      <IconButton data-testid={selected} color="inherit">
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
        >
          {selected.toUpperCase()}
        </Typography>
      </IconButton>
    </Link>
  );
};

interface TaskBarProps {
  array: string[];
}

const TaskBar: React.FC<TaskBarProps> = ({ array }) => {
  return (
    <AppBar
      position="sticky"
      style={{
        alignItems: "center",
        backgroundColor: COMPONENT_BACKGROUND_COLOR,
        maxWidth: "100vw",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
        }}
      >
        <div className="logo">
          <img
            src={logoImage}
            alt="Logo da Página"
            style={{
              marginTop: "1rem",
              height: "4rem",
            }}
          />
        </div>
        {array.map((type) => (
          <div key={type}>
            <IconButtonItem selected={type} />
          </div>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default TaskBar;
