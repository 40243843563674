export function generateId(): string {
  const characters = "abcdef0123456789";
  let id = "";

  for (let i = 0; i < 24; i++) {
    id += characters[Math.floor(Math.random() * characters.length)];
  }

  return id;
}

export function generateCode(): string {
  const characters = "abcdef0123456789";
  let code = "";

  for (let i = 0; i < 5; i++) {
    code += characters[Math.floor(Math.random() * characters.length)];
  }

  return code;
}

export function getCurrentDateString(): string {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function buildPath(path: string): string {
  return `/${path}`;
}

export function formatBlockToHTML(text: string): string {
  const paragraphs = text.split("\n");

  const formattedHTML = paragraphs
    .map((paragraph) => `${paragraph}<p>`)
    .join("");

  return formattedHTML;
}

export function normalizeTitle(title: string): string {
  return title
    .toLowerCase()
    .normalize("NFD")
    .replace(/[^\w\s]/g, "")
    .replace(/\s+/g, "-")
    .replace(/[\u0300-\u036f]/g, "");
}
