import { addDoc, collection } from "firebase/firestore";
import { db } from "../Firebase";

export async function sendSubscribeMail(
  email: string,
  code: string
): Promise<void> {
  await addDoc(collection(db, "mail"), {
    to: email,
    message: {
      subject: "Ativar Inscrição",
      text: `Entre o Codigo Abaixo para ativar Inscrição \n ${code}`,
    },
  }).catch((error) => console.error("Error", error));
}

export async function sendNewsMail(
  email: string[],
  collectionName: string,
  title: string,
  normalizedTitle: string
): Promise<void> {
  const address = `http://tocadojota.com.br/${collectionName}/${normalizedTitle}`;
  const unsubscribe = "http://tocadojota.com.br/unsubscribe";
  await addDoc(collection(db, "mail"), {
    bcc: email,
    message: {
      subject: `Nova Postagem na Toca do Jota:  ${collectionName.toUpperCase()} ${title}`,
      html: `Nova postagem na Toca do Jota. \n Visite ${address}  \n\n Para deixar de receber esse email acesse ${unsubscribe}`,
    },
  }).catch((error) => console.error("Error", error));
}

export async function notifyCommentMail(
  collectionName: string,
  title: string,
  normalizedTitle: string
): Promise<void> {
  const address = `http://tocadojota.com.br/${collectionName}/${normalizedTitle}`;
  const message = `Novo Comentario em: [${collectionName.toUpperCase()}] ${title}`;
  await addDoc(collection(db, "mail"), {
    to: process.env.REACT_APP_MAIL as string,
    message: {
      subject: message,
      text: `${message}. \n Veja em ${address}`,
    },
  }).catch((error) => console.error("Error", error));
}
