import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import React from "react";
import defaultImage from "../images/logo/logofundo.png";
import { ViewDoctItem } from "../libs/interface/ViewDocItem.interface";
import { useWindowWidth } from "../libs/commons/useWindowWidth";

interface CardItemProps {
  item: ViewDoctItem;
}

const CardItem: React.FC<CardItemProps> = ({ item }) => {
  const isMobile = useWindowWidth();
  const setPreview = (text: string | undefined): string | undefined => {
    if (text) {
      return text.split("<p>")[0];
    }

    return;
  };
  const getYoutubeEmbedUrl = (videoId: string): string => {
    return `https://www.youtube.com/embed/${videoId}`;
  };
  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        boxShadow: "0px 5px 5px",
        position: "relative",
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "space-between",
      }}
    >
      {item.videoId ? (
        <CardMedia
          component="iframe"
          // height="70%"
          title={item.title}
          src={getYoutubeEmbedUrl(item.videoId)}
          allowFullScreen
          style={{ maxHeight: "50%", overflow: "hidden" }}
        />
      ) : (
        <CardMedia
          component="img"
          // height="25%"
          image={item.imgSource ?? defaultImage}
          title={item.title}
          style={{ maxHeight: "30%", overflow: "hidden", objectFit: "cover" }}
        />
      )}
      <CardContent
        sx={{ fontSize: "large", textAlign: "justify", flexGrow: 1 }}
      >
        <Typography
          gutterBottom
          variant="body2"
          component="div"
          textAlign="left"
          fontWeight="bold"
          sx={{ marginTop: 1 }}
        >
          {item.title}
        </Typography>
        <Typography
          className="preview"
          variant="body2"
          color="text.secondary"
          style={{ maxHeight: "15vh", overflow: "hidden" }}
        >
          {setPreview(item.text)}
        </Typography>
        <Box
          className="foot"
          sx={{
            position: "absolute",
            bottom: 0, // Posicionando o rodapé no fundo do card
            width: "100%",
            textAlign: "right",
            paddingRight: isMobile ? "10vw" : "5vw",
            paddingBottom: 2,
          }}
        >
          <Typography
            variant="body2"
            color="blue"
            fontSize="0.8rem"
            fontStyle="italic"
          >
            Veja mais
          </Typography>
          <Typography
            textAlign="right"
            variant="body2"
            fontStyle="italic"
            color="text.secondary"
          >
            Postado em {new Date(item.date).toLocaleString()}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardItem;
