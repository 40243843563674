import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  ADMIN,
  COMPONENT_BACKGROUND_COLOR,
  UNSUBSCRIBE,
} from "../libs/commons/constants";
import { buildPath } from "../libs/commons/utils";

const BottomBar: React.FC = () => {
  return (
    <AppBar
      style={{
        marginTop: "1vw",
        width: "100%",
        alignItems: "center",
        backgroundColor: COMPONENT_BACKGROUND_COLOR,
      }}
      position="static"
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          gap: "50px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ fontSize: "0.8rem", fontStyle: "italic" }}
          >
            Desenvolvido em React por{" "}
            <Link
              to="https://www.linkedin.com/in/jo%C3%A3o-marques-3038a36a/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "white",
              }}
            >
              Jota Marques
            </Link>
          </Typography>
          <Link
            to={buildPath(ADMIN)}
            style={{ textDecoration: "none", color: "white" }}
          >
            <IconButton color="inherit">
              <Typography
                variant="h6"
                color="inherit"
                component="div"
                sx={{ fontSize: "0.5rem", fontWeight: "bold" }}
              >
                ADMIN LOGIN
              </Typography>
            </IconButton>
          </Link>
        </Box>
        <Link
          to={buildPath(UNSUBSCRIBE)}
          style={{
            textDecoration: "none",
            color: "white",
            marginLeft: "auto",
          }}
        >
          <IconButton color="inherit">
            <Typography
              variant="h6"
              color="inherit"
              component="div"
              sx={{ fontSize: "1rem", fontWeight: "bold" }}
            >
              DEIXAR DE SEGUIR
            </Typography>
          </IconButton>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default BottomBar;
