import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Link,
  Typography,
} from "@mui/material";
import lightBlue from "@mui/material/colors/lightBlue";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import AddComments from "../components/AddComments";
import Comments from "../components/Comments";
import defaultImage from "../images/logo/logofundo.png";
import { ViewDoctItem } from "../libs/interface/ViewDocItem.interface";
import { getCommentDocs } from "../libs/database/services/Comments.service";
import SubscribeButton from "../components/SubscribeButton";
import { StyledCenterContainer } from "../App";
import { useWindowWidth } from "../libs/commons/useWindowWidth";

interface ViewDocProps {
  item: ViewDoctItem;
}

const ViewDocument: React.FC<ViewDocProps> = ({ item }) => {
  const isMobile = useWindowWidth();
  const queryKey = item.collection + item.id;
  const [refresh, setRefresh] = useState<boolean>(false);
  const { data } = useQuery(
    queryKey,
    async () => {
      return await getCommentDocs(item);
    },
    {
      staleTime: 6000 * 50,
      cacheTime: 6000 * 50,
    }
  );

  const queryClient = useQueryClient();

  const handleCommentAdded = () => {
    queryClient.invalidateQueries({
      queryKey: [queryKey],
      refetchInactive: true,
    });
    setRefresh((prev) => !prev);
  };

  useEffect(() => {}, [refresh]);

  return (
    <StyledCenterContainer>
      <Card
        sx={{
          maxWidth: isMobile ? "100%" : "70%",
          height: "100%",
          boxShadow: "0px 5px 5px",
        }}
      >
        <CardContent sx={{ fontSize: "large", textAlign: "justify" }}>
          <SubscribeButton />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              alignSelf="right"
              gutterBottom
              variant="body2"
              component="div"
              fontStyle="italic"
              color={lightBlue}
              sx={{
                marginTop: 2,
                marginLeft: "auto",
                marginRight: 2,
              }}
            >
              Postado em {new Date(item.date).toLocaleString()}
            </Typography>
          </Box>
          {item.href ? (
            <Typography
              sx={{ fontSize: "large", textAlign: "justify" }}
              paragraph
            >
              Veja em{" "}
              <Link href={item.href} target="_blank" rel="noopener noreferrer">
                {item.href}
              </Link>
            </Typography>
          ) : null}
          {item.videoId ? (
            <iframe
              title={item.title}
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${item.videoId}`}
              allowFullScreen
            ></iframe>
          ) : (
            <CardMedia
              component="img"
              height="400vw"
              width="100%"
              image={item.imgSource ?? defaultImage}
              title={item.title}
              style={{ objectFit: "contain" }}
            />
          )}
          <Box
            sx={{
              margin: "2rem",
              fontSize: "1rem",
              "@media (max-width: 700px)": {
                fontSize: "0.85rem",
              },
            }}
          >
            <Box sx={{ textAlign: "left", fontWeight: "bold" }}>
              {item.title}
            </Box>
            <>
              {item.text !== null && item.text !== undefined && (
                <Box
                  sx={{
                    textAlign: "justify",
                  }}
                >
                  {item.text.split("<p>").map((text) => (
                    <p>{text}</p>
                  ))}
                </Box>
              )}
            </>
          </Box>

          <Box
            sx={{
              display: "column",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{
                marginLeft: 2,
              }}
            >
              COMENTARIOS:
            </Typography>

            <Comments items={data ?? []} />
            <AddComments item={item} onCommentAdded={handleCommentAdded} />
          </Box>
        </CardContent>
      </Card>
    </StyledCenterContainer>
  );
};

export default ViewDocument;
