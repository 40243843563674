import { Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  COMPONENT_BACKGROUND_COLOR,
  SUBSCRIBE,
} from "../libs/commons/constants";
import { useWindowWidth } from "../libs/commons/useWindowWidth";
import { buildPath } from "../libs/commons/utils";

const SubscribeButton: React.FC = () => {
  const isMobile = useWindowWidth();
  return (
    <div style={{ textAlign: "right" }}>
      <Link
        to={buildPath(SUBSCRIBE)}
        style={{ textDecoration: "none", color: "white" }}
      >
        <Button
          data-testid="button"
          color="inherit"
          variant="contained"
          style={{
            borderRadius: "20px",
            marginRight: "3vw",
            marginTop: "1vw",
            backgroundColor: COMPONENT_BACKGROUND_COLOR,
          }}
        >
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{
              fontSize: isMobile ? "2.5vw" : "1.5vw",
              fontWeight: "bold",
            }}
          >
            {SUBSCRIBE.toUpperCase()}
          </Typography>
        </Button>
      </Link>
    </div>
  );
};

export default SubscribeButton;
