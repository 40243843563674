import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { StyledCenterContainer } from "../App";
import IsLoading from "../components/IsLoading";
import SubscribeButton from "../components/SubscribeButton";
import logoImage from "../images/logo/logofundo.png";
import { ABOUT } from "../libs/commons/constants";
import { getAbout } from "../libs/database/services/About.service";
import { useWindowWidth } from "../libs/commons/useWindowWidth";

const About: React.FC = () => {
  const isMobile = useWindowWidth();
  const { data, isLoading, error } = useQuery(
    ABOUT,
    async () => {
      return await getAbout();
    },
    {
      staleTime: 3600000,
      cacheTime: 3600000,
    }
  );

  if (error) {
    console.error("Error fetching documents:", error);
  }

  if (isLoading) {
    return <IsLoading />;
  }

  return (
    <StyledCenterContainer>
      <Card
        sx={{
          maxWidth: isMobile ? "100%" : "50%",
          height: "100%",
          boxShadow: "0px 5px 5px",
        }}
      >
        <SubscribeButton />
        <CardMedia
          component="img"
          height="180"
          image={data?.imgSource ?? logoImage}
          style={{
            marginTop: "10px",
            maxWidth: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <CardContent sx={{ fontSize: "large", textAlign: "justify" }}>
          <Typography variant="body2">
            <div className="home-container">
              {data?.text.split("<p>").map((text) => (
                <p>{text}</p>
              ))}
              <p>Email: {data?.email}</p>
              <p style={{ fontWeight: "bold" }}>
                Você pode acompanhar a Toca do Jota nas redes sociais
              </p>
              <>
                {data?.socialMedia.map((socialMedia) => (
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    key={socialMedia.title}
                  >
                    {socialMedia.IconImgSource && (
                      <Grid item>
                        <a
                          href={socialMedia.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={socialMedia.IconImgSource}
                            alt={`${socialMedia.title} favicon`}
                            width="32"
                            height="32"
                          />
                        </a>
                      </Grid>
                    )}
                    <Grid item>
                      <a
                        href={socialMedia.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {socialMedia.title}
                      </a>
                    </Grid>
                  </Grid>
                ))}
              </>
            </div>
          </Typography>
        </CardContent>
      </Card>
    </StyledCenterContainer>
  );
};

export default About;
