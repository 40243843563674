import React, { useEffect } from "react";
import { useQuery } from "react-query";
import IsLoading from "../components/IsLoading";
import MainComponent from "../components/MainComponent";
import SubscribeButton from "../components/SubscribeButton";
import { countAllDocumentsByCollection } from "../libs/database/services/Documents.service";
import { ViewDoctItem } from "../libs/interface/ViewDocItem.interface";

interface MainProps {
  limit: number;
  collection: string;
  openItem: (item: ViewDoctItem) => void;
}

const Main: React.FC<MainProps> = ({ limit, collection, openItem }) => {
  useEffect(() => {}, [collection]);

  const { data, isLoading, error } = useQuery(
    `count${collection}`,
    async () => {
      return await countAllDocumentsByCollection(collection);
    },
    {
      staleTime: 6000 * 50,
      cacheTime: 6000 * 50,
    }
  );

  if (error) {
    console.error("Error fetching documents:", error);
  }

  if (isLoading) {
    return <IsLoading />;
  }

  return (
    <>
      <SubscribeButton />
      <MainComponent
        limit={limit}
        collection={collection}
        count={data ?? 0}
        openItem={openItem}
      />
    </>
  );
};

export default Main;
