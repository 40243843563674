import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../images/logo/logo.png";
import { COMPONENT_BACKGROUND_COLOR, HOME } from "../libs/commons/constants";
import { buildPath } from "../libs/commons/utils";

interface MenuButtonItemProps {
  func: () => void;
  selected: string;
}
const MenuButtonItem: React.FC<MenuButtonItemProps> = ({ func, selected }) => {
  return (
    <Link
      to={buildPath(selected)}
      style={{ textDecoration: "none", color: "white" }}
    >
      <MenuItem
        data-testid={selected}
        onClick={func}
        sx={{
          color: "white",
          fontWeight: "bold",
          backgroundColor: COMPONENT_BACKGROUND_COLOR,
        }}
      >
        {selected.toUpperCase()}
      </MenuItem>
    </Link>
  );
};

interface TaskBarProps {
  array: string[];
}

const TaskBarMobile: React.FC<TaskBarProps> = ({ array }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedType, setSelectedType] = useState(HOME);
  const navigate = useNavigate();

  useEffect(() => {
    const path = window.location.pathname;
    const selected = array.find((type) => path.includes(buildPath(type)));
    if (selected) {
      setSelectedType(selected);
    }
  }, [array]);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (type: any) => {
    setSelectedType(type);
    setAnchorEl(null);
    navigate(buildPath(type));
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="sticky"
      style={{
        alignItems: "center",
        backgroundColor: COMPONENT_BACKGROUND_COLOR,
        maxWidth: "100vw",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className="logo">
          <img
            src={logoImage}
            alt="Logo da Página"
            onClick={handleMenuClick}
            style={{
              marginTop: "1rem",
              height: "4rem",
            }}
          />
        </div>
        <IconButton
          data-testid="icon-button"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
        >
          {selectedType.toUpperCase()}
        </Typography>
        <Menu
          data-testid="menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          sx={{
            mt: "1px",
            "& .MuiMenu-paper": { backgroundColor: COMPONENT_BACKGROUND_COLOR },
          }}
        >
          {array.map((type) => (
            <div key={type}>
              <MenuButtonItem
                func={() => handleMenuItemClick(type)}
                selected={type}
              />
            </div>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
export default TaskBarMobile;
